@import '../../variables.scss';

.route-wrapper > div {
    min-height: calc(100vh - 84px);
}

.login-page-wrapper {
    height: 100vh;

    h1 {
        color: #041f1e;
        font-family: 'Le Monde Livre Pro';
        margin-bottom: 0;
    }

    h3 {
        color: #2b223b;
        font-size: 32px;
        font-family: 'Source Sans Pro';
        font-weight: 600;
    }

    input {
        width: 95%;
        margin-right: 21px;
    }

    .question {
        overflow-y: unset;
        @media screen and (max-width: $media-tablet) {
            padding: 0px 20px 21px;
        }
    }

    .login-form {
        padding-right: 21px;
    }
}

.create-account-data-form {
    height: 100vh;

    h1 {
        color: #041f1e;
        font-family: 'Le Monde Livre Pro';
        margin-bottom: 0;
    }

    h3 {
        color: #2b223b;
        font-size: 32px;
        font-family: 'Source Sans Pro';
    }

    .question.input-form {
        margin: 0 16.667%;
    }
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
}

.new-input-field {
    width: 550px !important;
    margin-bottom: 10px !important;

    @media screen and (max-width: $media-tablet) {
        max-width: 330px;

        p {
            font-size: 20px;
        }
    }

    p {
        font-family: 'Source Sans Pro';
        font-size: 20px;
        color: #d9085b !important;
        margin-top: 12px;
        padding: 0;
    }
}

.three-columns-wrapper {
    display: flex;
    justify-content: space-around;
}

.cb-wrapper {
    margin-bottom: 150px;
    display: flex;
    flex-wrap: wrap;

    .cb-holder {
        flex: 50%;

        @media screen and (max-width: 950px) {
            flex: 100%;
        }
    }
}

.answerWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.demoP {
    font-family: Le Monde Livre Pro;
    font-size: 48px;
    line-height: 66px;
    color: #d22584;
    margin: 0;
}

.slideinbottom-slideouttop-leave {
    opacity: 1;
    margin-top: 0px;
}

.slideinbottom-slideouttop-leave.slideinbottom-slideouttop-leave-active {
    opacity: 0.01;
    margin-top: -500px;
    transition: all 500ms ease-in;
}

.slideinbottom-slideouttop-appear {
    opacity: 0.6;
    margin-top: 800px;
}

.slideinbottom-slideouttop-appear.slideinbottom-slideouttop-appear-active {
    opacity: 1;
    margin-top: 0;
    transition: all 500ms ease-in;
}

.slideintop-slideouttop-leave {
    opacity: 1;
    margin-top: 0;
}

.slideintop-slideouttop-leave.slideintop-slideouttop-leave-active {
    opacity: 0.01;
    margin-top: -500px;
    transition: all 500ms ease-in;
}

.slideintop-slideouttop-appear {
    opacity: 0.6;
    margin-top: -400px;
}

.slideintop-slideouttop-appear.slideintop-slideouttop-appear-active {
    opacity: 1;
    margin-top: 0;
    transition: all 500ms ease-in;
}

.slideintop-slideoutbottom-leave {
    opacity: 1;
    margin-top: 0px;
}

.slideintop-slideoutbottom-leave.slideintop-slideoutbottom-leave-active {
    opacity: 0.01;
    margin-top: 500px;
    transition: all 500ms ease-in;
}

.slideintop-slideoutbottom-appear {
    opacity: 0.6;
    margin-top: 800px;
}

.slideintop-slideoutbottom-appear.slideintop-slideoutbottom-appear-active {
    opacity: 1;
    margin-top: 0;
    transition: all 500ms ease-in;
}

.fade-leave {
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 400ms linear;
}

.fade-appear {
    opacity: 0.01;
    margin-top: 300px;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: all 1000ms linear;
}

.first-item {
    .slideinbottom-slideouttop-appear.slideinbottom-slideouttop-appear-active {
        transition: none;
    }

    .slideintop-slideouttop-appear.slideintop-slideouttop-appear-active {
        transition: none;
    }

    .slideintop-slideoutbottom-appear.slideintop-slideoutbottom-appear-active {
        transition: none;
    }

    .slideintop-slideoutbottom-leave.slideintop-slideoutbottom-leave-active {
        transition: none;
    }
}

.broker-textfield {
    margin-bottom: 20px;
}

