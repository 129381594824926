@import '../../../variables.scss';

.input {
    border: none;
    color: #633ff2;
    font-size: 22px;
    padding: 12px 15px;
    width: 550px;
    font-size: 32px;

    &.center {
        text-align: center;
    }

    &.left {
        text-align: left;
        padding-left: 0;
    }

    &.right {
        text-align: right;
    }

    @media screen and (max-width: $media-tablet) {
        max-width: 330px;
        font-size: 20px;
    }
}