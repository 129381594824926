.card {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    span {
        padding: 0;
        margin: 0;
    }

    width: 276px;
    min-height: 100%;
    padding: 14px;
    border: 1px solid #ffd757;
    border-radius: 8px;
    background-color: transparent;
    text-align: center;

    h3 {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 20px;
        color: #041f1e;
    }

    p {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-top: 0;
        color: #041f1e;
    }
}
