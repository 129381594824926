@import '../../../variables.scss';

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 50%;
    margin-right: 12.5%;
    margin-left: 50px;

    @media screen and (max-width: $media-mobile) {
        margin-right: 20px;
    }

    .question-titles {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        padding: 0 20px;
        margin-bottom: 5px;

        .title {
            font-family: 'Source Sans Pro';
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            color: #041f1e;
            opacity: 1;
        }
    }

    .outer-bar {
        width: 100%;
        background-color: #dadada;
        height: 4px;
        border-radius: 2px;
        margin-top: 5px;

        .inner-bar {
            height: 100%;
            border-radius: 2px;
            background-color: #342996;
        }
    }
}
