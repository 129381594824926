.error-page-content {
    margin-left: 16.667%;
    margin-right: 12.5%;
    display: flex;
    position: relative;
    flex: 100%;
    justify-content: center;

    .text-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p:nth-child(odd) {
            margin-left: -150px;
        }

        p:nth-child(even) {
            margin-left: 150px;
        }
    }

    p {
        font-family: 'Source Sans Pro';
        font-size: 20px;
        color: #2b223b;
        max-width: 420px;
        margin-top: 50px;
    }

    .left-img {
        position: absolute;
        left: -90px;
        bottom: 50px;
    }

    .right-img {
        position: absolute;
        right: 0;
        bottom: 50px;
    }
}

.error-page {
    h2 {
        font-family: 'Le Monde Livre Pro';
        font-size: 48px;
        color: #041f1e;
        margin-left: 16.667%;
        font-weight: bold;
        margin-bottom: 0;
    }

    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.error-page-footer {
    background-color: #faf8f7;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 110px;

    .right-side {
        button:first-child {
            margin-right: 15px;
        }

        margin-right: 12.5%;
    }
}
