button {
    font-family: 'Source Sans Pro';
    cursor: pointer;
    margin-bottom: 20px;
    height: 48px;

    &.small {
        font-size: 16px;
        padding: 5px 35px;
    }

    &.medium {
        font-size: 20px;
        padding: 5px 65px;
    }

    &.large {
        font-size: 28px;
        padding: 5px 70px;
    }
}

.rounded-white {
    background-color: #fff;
    border-radius: 43px;
    color: #342996;
    border: none;
    cursor: pointer;
    font-weight: 600;
    box-shadow: 0px 10px 20px rgba(52, 41, 150, 0.4);

    &:hover {
        background-color: #d22584;
        color: #fff;
        box-shadow: 0px 10px 20px rgba(210, 37, 132, 0.4);
    }

    &.clicked {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.3)
            ),
            #d62686;
    }

    &.disabled {
        background: #fff;
        cursor: default;
        color: rgba(4, 31, 30, 0.5);
        box-shadow: none;
    }
}

.rounded-purple {
    background-color: #342996;
    border-radius: 43px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-weight: 600;
    border: 2px solid #342996;
    box-shadow: 0px 10px 20px rgba(52, 41, 150, 0.4);

    &:hover {
        background-color: #d22584;
        border: 2px solid #d22584;
        box-shadow: 0px 10px 20px rgba(210, 37, 132, 0.4);
    }

    &.clicked {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.3)
            ),
            #d62686;
    }

    &.disabled.small {
        background: linear-gradient(
                0deg,
                rgba(4, 31, 30, 0.5),
                rgba(4, 31, 30, 0.5)
            ),
            #ffffff;
    }

    &.disabled {
        background: rgba(4, 31, 30, 0.5);
        cursor: default;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    }
}

.rounded-transparent {
    background-color: transparent;
    border-radius: 43px;
    color: #342996;
    border: 2px solid #342996;
    cursor: pointer;
    font-weight: 600;

    &:hover {
        background-color: #d22584;
        border: 2px solid #d22584;
        color: #fff;
    }

    &.clicked {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.3)
            ),
            #342996;
        border: 2px solid #342996;
    }

    &.disabled {
        border: 2px solid rgba(4, 31, 30, 0.25);
        background-color: transparent;
        cursor: default;
        color: rgba(4, 31, 30, 0.3);
    }
}

.rounded-transparent-white {
    background-color: transparent;
    border-radius: 43px;
    color: #fff;
    border: 2px solid #fff;
    cursor: pointer;
    font-weight: 600;

    &:hover {
        background-color: #d22584;
        border: 2px solid #d22584;
        color: #fff;

        &.small {
            background-color: #fff;
            color: #342996;
            border: 2px solid #fff;
        }
    }

    &.clicked {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.3)
            ),
            #d62686;
        border: 2px solid
                linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            #d62686;

        &.small {
            background: #fff;
            color: #342996;
            border: 2px solid #fff;
        }
    }

    &.disabled {
        border: 2px solid rgba(4, 31, 30, 0.25);
        background-color: transparent;
        cursor: default;
        color: rgba(4, 31, 30, 0.3);
    }
}

.rect-transparent {
    border-radius: 8px;
    color: #342996;
    border: 2px solid #342996;
    background-color: transparent;

    margin-right: 25px;
    font-weight: bold;

    &:hover {
        background-color: rgba(52, 41, 150, 0.1);
    }

    &.clicked {
        background: rgba(52, 41, 150, 0.1);
    }

    &.large {
        max-width: 300px;
        max-height: 96px;
        width: 300px;
        font-size: 20px;
        height: 96px;
        padding: 0px;

    }

    &.medium {
        max-width: 228px;
        max-height: 72px;
        width: 228px;
        height: 72px;
        font-size: 20px;
        padding: 0px;
        min-inline-size: max-content;
        padding-right: 16px;
        padding-left: 16px;
    }
}
