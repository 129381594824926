@import '../../../variables.scss';

.footerContainer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 72px;
    z-index: 25;
    background-color: #faf8f7;

    @media screen and (max-width: $media-mobile) {
        height: 130px;
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    

    .left-side {
        button:first-child {
            margin: auto;
        }
        
        margin-left: 16.667%;
    }

    .right-side {
        button:first-child {
            margin: auto;
        }

        margin-right: 12.5%;

        .skipButton {
            margin-top: 0px;
        }
    }

    button {
        margin-bottom: 0 !important;
    }

    @media screen and (max-width: $media-mobile) {
        // height: 0px;
        margin-bottom: 20px;

        .left-side {
            margin-left: 12px;
        }

        .right-side {
            margin-right: 12px;
        }
    }
}
