@import '../../../variables.scss';

.back-button {
    font-family: 'Source Sans Pro';
    font-size: 20px;
    color: #041f1e;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        margin-left: 10px;
        cursor: pointer;
    }

    @media screen and (max-width: $media-mobile) {
        .back-button-text {
            display: none;
        }
    }
}
