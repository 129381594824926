.final-page-wrapper {
    .content {
        max-width: 1020px;
        margin: 0 12.5% 0 16.667%;
        display: flex;
        flex-direction: column;

        h1 {
            font-family: 'Le Monde Livre Pro';
            font-size: 72px;
            line-height: 99px;
            text-align: center;
            color: #041f1e;
            font-weight: normal;
            letter-spacing: 0;
        }

        .yellow-paragraph {
            background-color: #ffd757;
            border-radius: 8px;
            padding: 25px 40px;

            p {
                font-family: 'Source Sans Pro';
                font-style: normal;
                font-weight: normal;
                font-size: 32px;
                line-height: 48px;
                text-align: center;
                color: #041f1e;
                margin: 0;
            }
        }

        p {
            color: #041f1e;
            text-align: center;
            font-size: 32px;
            font-family: 'Source Sans Pro';
            line-height: 48px;
            margin-top: 35px;
            font-weight: normal;
        }

        button {
            margin: 0 auto;
        }
    }
}
