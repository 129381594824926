code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

body {
    font-family: 'IBM Plex Sans', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff !important;
}

div#root > div {
    margin: 0;
    padding: 0;
}

.questionHeaderHolder {
    padding: 20px;
}

.backLink {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 16px;
    display: inline-block;
    align-items: center;
    text-align: center;
    letter-spacing: 0.75px;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
    border: 0;
}

.backLink span {
    margin-left: 15px;
}

.backLink:hover {
    cursor: pointer;
}

a.saveLink {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #542eea;
    right: 20px;
    top: 20px;
    display: inline-block;
    float: right;
    text-decoration: none;
    margin-left: 20px;
}

h1 {
    color: #041f1e;
    letter-spacing: 0.5px;
    font-family: 'Le Monde Livre Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 66px;
    text-align: left;
    margin-bottom: 24px;
    padding: 0;
}

h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 60px;
    letter-spacing: 0.25px;
    text-align: left;
}

.bold {
    font-weight: bold !important;
}

.question {
    padding: 0 12.5% 84px 16.667%;
}

@media screen and (max-width: 450px) {
    .question {
        padding: 0 24px 100px 21px;
    }
}

.question .question {
    margin: 0;
    padding-top: 0;
}

.question .question .answerWrapper {
    text-align: center;
}

.question h2 {
    margin: 0 0 24px 0;
}

h3 {
    margin-top: 0;
    font-family: 'Source Sans Pro';
    font-size: 32px;
    color: #2b223b;
    line-height: 48px;
    margin-bottom: 36px;
    font-weight: normal;
    white-space: pre-wrap;
}

.question input[type='text'],
.question input[type='password'],
.question input[type='email'],
input[type='tel'],
input[type='number'] {
    border: none;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    letter-spacing: 0.25px;
    color: #2b223b;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.question .question h2 {
    margin-top: 0;
    font-family: 'Source Sans Pro';
    font-size: 32px;
    color: #2b223b;
    line-height: 48px;
    max-width: 558px;
    margin-bottom: 65px;
}

.question button.questionButton.clicked {
    background-color: #e1d9ff;
}

.question .dd-wrapper {
    width: 70%;
    font-family: 'IBM Plex Sans', sans-serif;
}

.question .dd-wrapper div div > div {
    min-height: 2.1874em;
}

.question .dd-wrapper label[data-shrink='true'] {
    display: none;
}

.question .dd-wrapper label,
.dd-menu-item {
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 24px !important;
    line-height: 33px !important;
    letter-spacing: 0.5px !important;
}

.question div.dd {
    width: 80%;
}

.route-wrapper {
    position: relative;
}

.route-wrapper > div {
    position: absolute;
}

input {
    padding: 10px 15px;
    color: #633ff2;
    font-size: 22px;
}

.input-field-error {
    color: red;
    padding-left: 30px;
}

.full-width {
    width: 100% !important;
}

.cb-holder {
    width: 50%;
    float: left;
    margin-bottom: 24px;
}

.cb-holder .cb {
    display: block;
    width: 20px;
    float: left;
    padding-top: 5px;
}

.cb-wrapper.single-column {
    flex-direction: column;
}

.cb-holder .label {
    float: left;
    width: 80%;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.5px;
}

.col {
    display: flex;
    vertical-align: middle;
}

.col-container {
    display: table;
    width: 100%;
    margin: 50px 0 !important;
}

.col-content-holder {
    height: 100%;
    display: flex;
    border: 1px solid #ccc;
    padding: 10px;
    background: #ffffff;
    border-color: transparent;
    box-shadow: -4px -2px 12px rgba(0, 0, 0, 0.1),
        4px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.col-content-holder h2 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 1px;
    color: #542eea;
}

.col-content-holder p {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #2b223b;
}

.col-content {
    display: inline-block;
    margin: auto;
}

.added-item-list {
    padding: 0;
    margin: 0 0 24px 0;
}

.added-item-list li {
    list-style: none;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2b223b;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.added-item-list img {
    margin-right: 10px;
    cursor: pointer;
}

.autocomplete-dd > div {
    max-width: 556px;
    border: none;
    border-radius: 0;
}

.autocomplete-dd > div:focus-within {
    box-shadow: none;
    border-bottom: 2px solid #342996;
}

.autocomplete-dd div:nth-child(3) > div > div > div {
    font-size: 20px;
    line-height: 28px;
}

.autocomplete-dd > div div {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;
}

.autocomplete-dd > div div:nth-child(2) span {
    visibility: hidden;
}

.autocomplete-dd > div div:nth-child(2) svg {
    visibility: hidden;
}

.error-message {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.5px;
    color: #d9085b;
    padding: 24px 0;
}

*:focus {
    outline: 0 !important;
}

.input-form input {
    text-align: left !important;
    width: 600px;
}

@media only screen and (max-width: 700px) {
    h1 {
        font-size: 32px;
        line-height: 45px;
    }

    h2 {
        font-size: 24px;
        line-height: 36px;
    }

    .question h3 {
        font-size: 20px;
        line-height: 32px;
    }

    .question {
        padding-top: 0;
    }

    .autocomplete-dd > div {
        width: 100%;
        font-size: 22px;
    }

    .autocomplete-dd > div div {
        font-size: 22px;
    }

    .question input[type='text'],
    input[type='tel'],
    input[type='number'] {
        width: 90%;
    }

    .question .question h2 {
        text-align: left;
    }

    .question button {
        margin-right: 0;
    }

    .question .dd-wrapper {
        width: 100%;
    }

    .question .question .questionButton {
        width: 100%;
        margin: 20px 0px 20px 0 !important;
    }
}

.faq {
    display: flex;
    flex-direction: row;
    height: 90%;
}

.faq-titleCol {
    display: flex;
    flex: 1;
    justify-content: center;
}

.faq-titleContent {
    display: flex;
    flex-direction: column;
    position: fixed;
}

.faq-answersCol {
    flex: 2;
    overflow-y: auto;
}

.faq-paperHeader {
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #e1e7ed;
    margin-bottom: 4px;
    margin-left: 40px;
}

.faq-accordion {
    color: #092744;
}

.faq-body1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30.17px;
}

.faq-title {
    font-size: 50px;
}

.faq-titles {
    font-size: 32px;
}

.faq-tableOfContents {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    font-size: 30px !important;
}

.faq-tableOfContents a {
    color: #4d4f50;
    text-decoration: none;
}

.faq-answerHeader {
    text-decoration: underline;
}
