@import '../../../variables.scss';

.navbar-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    background-color: #fff;
    position: relative;
    z-index: 5;

    img {
        margin-left: 16.667%;
    }

    @media screen and (max-width: $media-mobile) {
        height: 60px;

        img {
            margin-left: 12px;
        }
    }

    .navbar-faq {
        float: right;
        margin-right: 12.5%;
    }
    
}

.navbar-faq-close {
    width: 48px;
    margin: 0px !important;
    position: relative;
    left: 97%;
}