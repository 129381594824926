.info-page-wrapper {
    height: 100%;

    .content {
        margin: 0 16.667%;
        h1 {
            font-family: 'Le Monde Livre Pro';
            font-size: 72px;
            line-height: 99px;
            color: #041f1e;
        }

        p {
            font-family: 'Source Sans Pro';
            font-size: 32px;
            line-height: 48px;
            color: #2b223b;
        }
    }
}
